/*
 * Sidebar
 */

 .sidebar {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 60px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 99; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
  /*border-right: 1px solid #3E649229;*/
}


.sidebar-expanded {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 250px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 99; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
  /*border-right: 1px solid #3E649229;*/
}

/* .nav-item {
  margin: 1em auto;
} */

.sidebar-link, .sidebar-expanded {
 color: black;
}

.sidebar .active {

 color: #000000; 
 outline: none;
 cursor: pointer; 
 text-decoration: none;
 position: relative;
}


.sidebar-expanded .active {
  color: #000000; 
  outline: none;
  cursor: pointer; 
  text-decoration: none;
  position: relative;
 }
 

 /* Hide scrollbar for Chrome, Safari and Opera */
 .sidebar::-webkit-scrollbar, .sidebar-expanded::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar, .sidebar-expanded {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 